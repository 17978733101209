module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"omitPrismicScript":true,"repositoryName":"bironsite","accessToken":"MC5Yb2piU0JBQUFIQjNyRHc4.77-9FV_vv70077-9PSnvv73vv70j77-9Dzrvv73vv70ePu-_vUXvv70y77-9fGXvv73vv73vv71e77-9Nu-_vQ"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
